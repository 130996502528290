h1{
  font-size: 1.35rem;
  font-family: libre;
}

@font-face{
  font-family: libre;
  src: local("LibreBasketville-Regular"), url("./fonts/LibreBaskerville-Regular.ttf") format("truetype")
}

@font-face{
  font-family: libreBold;
  src: local("LibreBasketville-Bold"), url("./fonts/LibreBaskerville-Bold.ttf") format("truetype")
}

.Spa-header {
  display: flex;
  padding: 1em;
  text-align: center;
}


@media (width <= 430px)
{
  header{
    font-size: 0.5rem;
    justify-content: center;
  }
}

.Rose{
  margin-top: -10px;
  margin-right: 10px;
  width: 10vh;
  height: 10vh;
  float: left;
  objectFit: "cover";
}

.MainBody{
  display: flex;
  background-color: bisque;
  min-width: 100vw;
  justify-content: center;
  align-items: center;
  margin-top: -2.5rem;
  height: 100svh;

  flex-direction: column;
}

.SpaImage
{
  display: inline-block;
  width: 25%;
  height: 45%;
  animation: fadeIn;
  animation-duration: 3s;
}

@media (width <= 430px)
{
  .SpaImage
  {
    display: inline-block;
    width: 90vw;
    height: 40vh;
    animation: fadeIn;
    animation-duration: 3s;
  }
}

.MenuOptions
{
  font-family: libreBold;
  font-size: 20px;
  margin-left: 900px;
  margin-top: 10.5px;
}

.AppointmentBody
{
  background-color: midnightblue;
  height: 150vh;
  margin-top: -8.1vh;
}

@media (width <= 430px)
{
  .AppointmentBody
  {
    margin-top: -6.5vh;
  }
}

.BodyTextDiv
{
  position: relative;
  text-align: center;
  top: 7vh;
  color: white;
  font-family: 'BlinkMacSystemFont';
}

@media (width <= 430px)
{
  .BodyTextDiv
  {
    position: relative;
    text-align: center;
    top: 7vh;
    color: white;
  }
}

.MassageImageContainer
{
  position: relative;
  background-color: crimson;
  height: 305px;
  width: 295px;
  top: -109vh;
  left: 10vw;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  border-radius: 2%;
}

@media (width <= 430px)
{
  .MassageImageContainer
  {
    top: -120vh;
    left: 12vw;
  }
}

.BodyMassageImage{
  height:300px;
  width:290px;
  overflow: hidden;
  border-radius: 2%;
}

.BMText
{
  position: absolute;
  top: 2px;
  background-color: forestgreen;
  width: 290px;
  border-radius: 3%;
  text-align: center;
  color: black;
  font-family: Libre;
}

.BMTextHidden
{
  font-size:0;
  background-color:transparent;
}

.HiddenButton
{
  position: absolute;
  opacity:0;
  scale:0;
}

.UnhiddenButton
 {
  height:300px;
  width:290px;
  border: black 2px;
  position: absolute;
  opacity:1;
  scale:1;
  animation-name: fadeIn;
  animation-duration: 4s;
 }

.BodyStyling1
{
  position: absolute;
  z-index: 0;
  background-color: navy;
  height: 250px;
  min-width: 100%;
  top: 500px;

}

.BodyMassageDescription
{
  display: inline-block;
  position: relative;
  color: azure;
  border: 2px crimson solid;
  border-radius: 3%;
  width: 30vw;
  padding: 10px;
  min-height: 53vh;
  top: -38vh;
  left: 50vw;
  animation-name: fadeIn;
  animation-duration: 4s;
}

@media (width <= 430px)
{
  .BodyMassageDescription
  {
    left: -26vw;
    top: 12vh;
    width: 90vw;
    padding: 10px;
    min-height: 50vh;
  }
  @media (height <= 720px)
  {
    .BodyMassageDescription
    {
      left: -26vw;
      top: 12vh;
      width: 90vw;
      padding: 10px;
      min-height: 68vh;
    }
  }
}

.AppointmentTitle
{
  /*display: flex;*/
  /*align-content: center;*/
  /*margin: auto;*/
  margin-top: 5px;
  margin-left: 300px;
}

.FormBody
{
    background: #1a1a1a;
    height: 200vh;
    margin-top: -90px;
}

input,
select {
  padding: 0 0.5rem;
  height: 2rem;
  border-radius: 4px;
  border: none;
  box-sizing: border-box;
  margin-bottom: 0.5rem;
}

form {
  position: absolute;
  top: 8rem;
  left: 35rem;
  width: 23rem;
  color: red;
}

input[type="submit"] {
  margin-top: 2rem;
  height: 2.5rem;
  font-size: 1rem;
  color: #fff;
  background: #0971f1;
  cursor: pointer;
}

input,
select {
  padding: 0 0.5rem;
  width: inherit;
  height: 2rem;
  border-radius: 4px;
  border: none;
  box-sizing: border-box;
}

textarea {
  height: 7rem;
  width: 22.5rem;
  margin-top: 5px;
  margin-bottom: 10px;
}

label {
  display: block;
  color: #fff;
}




